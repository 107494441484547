import React, { MouseEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import { ConnectedProps } from 'react-redux';
import {
  getInternalLinkRoute,
  SECTION_BLOG_PAGE,
  SECTION_BLOG_POST_PAGE,
} from '@wix/communities-blog-client-common';
import { getPathWithoutSectionUrl } from '../../services/get-path-without-section-url';
import { isAppRoute } from '../../services/section-route-utils';
import { isSite as getIsSite } from '../../store/basic-params/basic-params-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { connect } from '../runtime-context';
import styles from './internal-link.scss';

const OMIT_PROPS = [
  'dispatch',
  'titleFontClassName',
  'tReady',
  'lng',
  'contentFontClassNameWithStyle',
];

type OwnProps = {
  to?: string;
  push?: () => {};
  className?: string;
  children?: ReactNode;
  addHoverClasses?: boolean;
  topology?: object;
  sectionId?: string;
  navigateWithoutScroll?: boolean;
  isActive?: boolean;
  useCustomRouter?: boolean;
  fullRoute?: string;
  onNavigation?: () => void;
};

type RuntimeProps = ConnectedProps<typeof connector>;

const InternalLink = ({
  addHoverClasses = true,
  children,
  className,
  isSite,
  sectionId: section,
  to,
  topology,
  useCustomRouter,
  navigateInPreview,
  fullRoute,
  navigateWithinBlog,
  navigateWithoutScroll,
  isActive,
  onNavigation,
  ...props
}: OwnProps & RuntimeProps) => {
  const shouldNavigateToFeed = section === SECTION_BLOG_PAGE || isAppRoute(to);
  const sectionId = shouldNavigateToFeed
    ? SECTION_BLOG_PAGE
    : SECTION_BLOG_POST_PAGE;
  const route =
    fullRoute ||
    getInternalLinkRoute({
      topology,
      to,
      shouldNavigateToFeed,
    });
  const linkClassName = classNames(
    addHoverClasses && 'blog-link-hover-color',
    className,
    navigateWithoutScroll && styles.noScrollButton,
  );
  const linkProps = omit(props, OMIT_PROPS);

  const handleClick = (event: MouseEvent) => {
    if (!isSite) {
      event.preventDefault();
      navigateInPreview(route, sectionId);
    } else if (navigateWithoutScroll) {
      navigateWithinBlog(
        getPathWithoutSectionUrl({
          route,
          topology,
          shouldNavigateToFeed,
        }),
        {
          disableScrollToTop: true,
        },
      );
    }
    if (onNavigation) {
      onNavigation();
    }
  };

  if (navigateWithoutScroll) {
    return (
      <button
        aria-current={isActive ? 'page' : undefined}
        onClick={handleClick}
        className={linkClassName}
        {...linkProps}
      >
        {children}
      </button>
    );
  }

  return (
    <a
      href={route}
      onClick={handleClick}
      className={linkClassName}
      data-hook="link"
      {...linkProps}
    >
      {children}
    </a>
  );
};

const connector = connect((state, _ownProps, actions) => ({
  topology: getTopology(state),
  isSite: getIsSite(state),
  navigateInPreview: actions.navigateInPreview,
  navigateWithinBlog: actions.navigateWithinBlog,
}));

export default connector(InternalLink);
